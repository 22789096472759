<template>
  <!-- 官方文件 -->
  <div>
    <!-- 头部 -->
    <Footer></Footer>
    <!-- 内容区域 -->
    <div class="content">
      <div class="content_box">
        <span class="content_box_tilte">
          “大唐杯”全国大学生新一代信息通信技术大赛 官方通知发布</span>
        <div class="content_box_BOX">
          <img src="./File_img/TonZhi.png" class="ss" alt="" />
          <span class="content_inform">官方通知</span>
          <div class="content_text">
            <!-- <p>
              <el-link type="info" @click="openPdf8"
                style="font-size: 0.16rem">关于邀请参加第十届“大唐杯”全国大学生新一代信息通信技术大赛全国总决赛的通知</el-link>
            </p>
            <p>
              <el-link type="info" @click="openPdf7"
                style="font-size: 0.16rem">第十届“大唐杯”全国大学生新一代信息通信技术大赛 延期通知</el-link>
            </p> -->
            
            <p  v-for="(item, index) in officalNotification" :key="index">
              <el-link type="info" @click="openfile(item.accessory)" style="font-size: 0.16rem">{{ item.title }}</el-link>
            </p>
          </div>
          <!-- <div class="pagination">
                  <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="60">
                </el-pagination>
              </div> -->
        </div>
      </div>

      <div class="content_box">
        <span class="content_box_tilte">
          “大唐杯”全国大学生新一代信息通信技术大赛 官方文件发布</span>
        <div class="content_box_BOXTwo">
          <img src="./File_img/WenJ.png" class="pp" alt="" />
          <span class="content_inform">官方文件</span>
          <div class="content_text">
            <p v-for="(item, index) in getData" :key="index">
              <el-link type="info" @click="openfile(item.accessory)" style="font-size: 0.16rem">{{ item.title }}</el-link>
            </p>
          </div>
          <div class="pagination">
            <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
              :hide-on-single-page="true" :pager-count="7" :page-size="6" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>

      <div class="content_box">
        <span class="content_box_tilte">
          “大唐杯”全国大学生新一代信息通信技术大赛往届文件</span>
        <div class="content_box_two">
          <div class="content_box_B">
            <div class="content_text_two">
              <img class="content_backimg" src="./File_img/backimg.png" alt="" />
              <p>
                <el-link type="info" @click="skip4" style="font-size: 0.16rem">第十届大唐杯全国大学生新一代信息通信技术大赛官方文件汇总</el-link>
              </p>
              <p>
                <el-link type="info" @click="skip3" style="font-size: 0.16rem">第九届大唐杯全国大学生新一代信息通信技术大赛官方文件汇总</el-link>
              </p>
              <p>
                <el-link type="info" @click="skip2" style="font-size: 0.16rem">第八届大唐杯全国大学生新一代信息通信技术大赛官方文件汇总</el-link>
              </p>
              <p>
                <el-link type="info" @click="skip1" style="font-size: 0.16rem">第七届大唐杯全国大学生新一代信息通信技术大赛官方文件汇总</el-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Bottom></Bottom>
  </div>
</template>
  
<script>
// @ is an alias to /src
import Footer from "@/components/Footer/Footer.vue";
import Bottom from "@/components/Bottom/Bottom.vue";
import $ from "jquery";
import { getLogin } from "api/apis.js";

export default {
  name: "Home",
  components: {
    Footer,
    Bottom,
  },
  data() {
    return {
      total: "",
      page: "1",
      Title: "",
      getData: "",
      officalNotification: []
    };
  },
  mounted() {
    this.Acquire();
    this.notifyFiles();
  },
  methods: {
    handleCurrentChange(val) {
      console.log(val);
      this.page = val;
      this.Acquire();
    },
    Acquire() {
      let dataobj = new FormData();
      dataobj.append("label", "文件"); //文件
      dataobj.append("page", this.page); //页数
      dataobj.append("limit", "6"); //条数

      getLogin.getFile(dataobj).then((res) => {
        console.log(res);
        console.log(JSON.parse(res.data));
        this.getData = JSON.parse(res.data);

        this.getData.map((item, index) => {
          console.log(JSON.parse(item.accessory));
        });
        // console.log(JSON.parse(Data[0].accessory))
        this.total = res.count;
      });
    },
    notifyFiles(){
      let dataobj = new FormData();
      dataobj.append("label", "通知"); //文件
      dataobj.append("page", this.page); //页数
      dataobj.append("limit", "6"); //条数

      getLogin.getFile(dataobj).then((res) => {
        console.log(res);
        console.log(JSON.parse(res.data));
        this.officalNotification = JSON.parse(res.data);
      });
    },
    openfile(val) {
      console.log(JSON.parse(val));
      if (JSON.parse(val).length == 0) {
        this.$message.error("无文件！");
        return false;
      } else {
        // console.log(JSON.parse(val)[0].url)
        window.open(JSON.parse(val)[0].url);
      }

      // window.open('./static/WenJ/大唐杯”全国大学生新一代信息通信技术大赛-大赛总章程.pdf')
    },
    openPdf() {
      window.open(
        "./static/TongZ/01-关于邀请参加第十届大唐杯全国大学生新一代信息通信技术大赛-通知.pdf"
      );
    },
    openPdf1() {
      window.open(
        "./static/TongZ/第九届“大唐杯”全国大学生新一代信息通信技术大赛-竞赛通知.pdf"
      );
    },
    openPdf2() {
      window.open(
        "./static/TongZ/第九届“大唐杯”全国大学生新一代信息通信技术大赛-全国总决赛（北区）闭幕式暨颁奖典礼行程安排.pdf"
      );
    },
    openPdf3() {
      window.open(
        "./static/TongZ/第九届“大唐杯”全国大学生新一代信息通信技术大赛-全国总决赛（南区）闭幕式暨颁奖典礼行程安排.pdf"
      );
    },
    openPdf4() {
      window.open(
        "./static/TongZ/第九届“大唐杯”全国大学生新一代信息通信技术大赛全国总决赛竞赛安排.pdf"
      );
    },
    openPdf5() {
      window.open(
        "./static/TongZ/第九届“大唐杯”全国大学生新一代信息通信技术大赛-省赛竞赛安排.pdf"
      );
    },
    openPdf6() {
      window.open(
        "./static/TongZ/关于邀请参加第十一届“大唐杯”全国大学生新一代信息通信技术大赛的通知.pdf"
      );
    },
    openPdf7() {
      window.open(
        "./static/TongZ/第十届“大唐杯”全国大学生新一代信息通信技术大赛（原“大唐杯”全国大学生移动通信5G技术大赛 ）延期通知(2).pdf"
      );
    },
    openPdf8() {
      window.open(
        "https://picture-file-public-read-dt.oss-cn-hangzhou.aliyuncs.com/dt_cup/official_content/%E5%85%B3%E4%BA%8E%E9%82%80%E8%AF%B7%E5%8F%82%E5%8A%A0%E7%AC%AC%E5%8D%81%E5%B1%8A%E2%80%9C%E5%A4%A7%E5%94%90%E6%9D%AF%E2%80%9D%E5%85%A8%E5%9B%BD%E5%A4%A7%E5%AD%A6%E7%94%9F%E6%96%B0%E4%B8%80%E4%BB%A3%E4%BF%A1%E6%81%AF%E9%80%9A%E4%BF%A1%E6%8A%80%E6%9C%AF%E5%A4%A7%E8%B5%9B%E5%85%A8%E5%9B%BD%E6%80%BB%E5%86%B3%E8%B5%9B%E7%9A%84%E9%80%9A%E7%9F%A5.pdf"
      );
    },
    openfile1() {
      window.open(
        "./static/WenJ/“大唐杯”全国大学生新一代信息通信技术大赛-投诉检具处理措施.pdf"
      );
    },
    openfile2() {
      window.open(
        "./static/WenJ/第九届“大唐杯”全国大学生新一代信息通信技术大赛-参赛通知.pdf"
      );
    },
    openfile3() {
      window.open(
        "./static/WenJ/第九届“大唐杯”全国大学生新一代信息通信技术大赛-大纲.pdf"
      );
    },
    openfile4() {
      window.open(
        "./static/WenJ/第九届“大唐杯”全国大学生新一代信息通信技术大赛-全国总决赛参赛通知.pdf"
      );
    },
    openfile5() {
      window.open(
        "./static/WenJ/第九届“大唐杯”全国大学生新一代信息通信技术大赛-章程.pdf"
      );
    },
    openfile6() {
      window.open(
        "./static/WenJ/第十届大唐杯全国大学生新一代信息通信技术大赛（省赛）-信息通信工程实践赛道竞赛大纲.pdf"
      );
    },
    openfile7() {
      window.open(
        "./static/WenJ/第十届大唐杯全国大学生新一代信息通信技术大赛（省赛）-5G创新应用仿真设计赛说明.pdf"
      );
    },
    openfile8() {
      window.open(
        "./static/WenJ/第十届大唐杯全国大学生新一代信息通信技术大赛（省赛）-5G创新应用仿真设计赛设计方案-模板.docx"
      );
    },
    skip1() {
      this.$router.push(`/Seventh`);
      // this.$router.push({path:`/Seventh`,query:{id:id}});
    },
    skip2() {
      this.$router.push("/Eighth");
    },
    skip3() {
      this.$router.push("/Ninth");
    },
    skip4() {
      this.$router.push("/Tenth");
    },
  },
};
</script>
  
<style scoped lang="scss">
/deep/ .el-table .cell.el-tooltip {
  text-align: left;
}

/deep/.el-link__inner {
  color: #000;
  font-size: 0.16rem;
}

/deep/.el-link__inner:hover {
  color: #fe850f;
}

@import "./File.scss";
</style>